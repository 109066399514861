<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<v-container fluid class="pa-5 pa-md-15">
				<v-row>
					<v-col cols="8">
						<h1 class="font-weight-bold">Klanten | <span class="font-weight-regular">Overzicht</span></h1>
						<p>Klanten die een bestellingen hebben geplaatst of een abonnementen hebben lopen.</p>
					</v-col>
					<v-col cols="4" class="ml-auto text-right">
						<v-btn color="secondary" elevation="0" @click="openMenu">Nieuwe klant toevoegen</v-btn>
					</v-col>
				</v-row>
				<v-card elevation="0" class="p-3 h-100">
					<v-data-table :headers="headers" :items="customers" :items-per-page="25" @click:row="handleClick"> </v-data-table>
				</v-card>
			</v-container>
		</v-main>
		<Drawer :direction="'right'" :exist="true" ref="LeftDrawer">
			<v-container fluid class="pa-5 pa-md-15">
				<h2>Nieuwe klant toevoegen</h2>

				<v-row class="ma-4"></v-row>
				<v-row>
					<v-col cols="12">
						<v-text-field :rules="rules.name" label="Bedrijfsnaam" outlined persistent-hint></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" md="6"> <v-text-field :rules="rules.name" label="Voornaam" outlined persistent-hint></v-text-field> </v-col
					><v-col cols="12" md="6">
						<v-text-field :rules="rules.name" label="Achternaam" outlined persistent-hint></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="ml-auto text-right">
						<v-btn color="secondary" right @click="newcustomer" elevation="0"> Aanmaken </v-btn>
					</v-col>
				</v-row>
			</v-container>
		</Drawer>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Drawer from "@/components/Drawer.vue";
import config from "@/config";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Drawer,
	},
	methods: {
		openMenu() {
			if (this.$refs.LeftDrawer.active) {
				this.$refs.LeftDrawer.close();
			} else {
				this.$refs.LeftDrawer.open();
			}
		},
		handleClick(v) {
			this.$router.push(v.to);
		},
		newcustomer() {
			// HIER WORD GEPOST NAAR DE BACKEND
			// DAARNA DOOR ROUTEN
		},
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Klanten",
				disabled: true,
				href: "/customers",
			},
		],
		headers: [
			{
				text: "ID",
				align: "start",
				sortable: false,
				value: "id",
			},
			{ text: "Naam", value: "name" },
			{ text: "Laatste bestelling", value: "lastorder" },
			{ text: "E-mail", value: "email" },
			{ text: "Abonnementen", value: "subscription" },
			{ text: "Bestellingen", value: "orders" },
			{ text: "Totale uitgaven", value: "totalspend" },
			{ text: "Land", value: "country" },
			{ text: "Plaats", value: "location" },
		],
		rules: {
			user: [
				value => !!value || 'Required.',
				value => (value && value.length >= 2) || 'Minimum 2 characters',
				value => (value && !(value.length > 50)) || 'Maximum 24 characters',
				value => (config.regex.user.test(value)) || 'Invalid characters',
			],
		},
		customers: [
			{
				id: 1,
				name: "gianni hesseling",
				to: `/customer/1`
			}
		]
	}),
};
</script>

