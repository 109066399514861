<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<v-container fluid class="pa-5 pa-md-15">
				<v-row>
					<v-col cols="8">
						<h1 class="font-weight-bold">Kortingscodes | <span class="font-weight-regular">Overzicht</span></h1>
						<p>Een overzicht van al je kortingscodes. Beheer en pas ze aan indien nodig.</p>
					</v-col>
					<v-col cols="4" class="ml-auto text-right">
						<v-btn color="secondary" elevation="0" @click="openMenu">Nieuwe kortingscode toevoegen</v-btn>
					</v-col>
				</v-row>
				<v-card elevation="0" class="p-3 h-100">
					<v-data-table :headers="headers" :items="coupons" :items-per-page="25" @click:row="handleClick"> </v-data-table>
				</v-card>
			</v-container>
		</v-main>
		<Drawer :direction="'right'" :exist="true" ref="LeftDrawer">
			<v-container fluid class="pa-5 pa-md-15">
				<h2>Nieuwe kortingscode toevoegen</h2>

				<v-row class="ma-4"></v-row>

				<v-row>
					<v-col cols="12">
						<v-text-field v-model="coupon.name" label="Kortingscode" outlined></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-textarea v-model="coupon.discription" outlined label="Beschrijving (optioneel)"></v-textarea>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="ml-auto text-right">
						<v-btn color="secondary" right @click="newcoupon" elevation="0"> Aanmaken </v-btn>
					</v-col>
				</v-row>
			</v-container>
		</Drawer>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Drawer from "@/components/Drawer.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Drawer,
	},

	methods: {
		openMenu() {
			if (this.$refs.LeftDrawer.active) {
				this.$refs.LeftDrawer.close();
			} else {
				this.$refs.LeftDrawer.open();
			}
		},
		handleClick(v) {
			this.$router.push(v.to);
		},
		newcoupon() {
			// HIER WORD GEPOST NAAR DE BACKEND
			// DAARNA DOOR ROUTEN
		},
	},

	data: () => ({
		coupon: {
			id: 1,
			name: "",
			description: "",
			date: "",
		},
		breadcrumb: [
			{
				text: "Kortingcodes",
				disabled: true,
				href: "/coupons",
			},
		],

		headers: [
			{
				text: "Code",
				align: "start",
				sortable: false,
				value: "name",
			},
			{ text: "Type kortingscode", value: "typekortingscode" },
			{ text: "Waarde van kortingscode", value: "valuecoupon" },
			{ text: "Beschrijving", value: "description" },
			{ text: "Gebruikerslimiet", value: "limit" },
			{ text: "Vervaldatum", value: "duedate" },
			{ text: "Status", value: "status" },
		],
		coupons: [
			{
				name: "KORTINGCODE10",
				typekortingscode: "Vaste winkelmand korting",
				valuecoupon: "10%",
				description: "Dit is en omschrijving.",
				limit: "4/100",
				duedate: "22/02/2022",
				status: "Actief",
				to: `/coupon/1`,
			},
			{
				name: "KORTINGCODE10",
				typekortingscode: "Vaste winkelmand korting",
				valuecoupon: "10%",
				description: "Dit is en omschrijving.",
				limit: "4/100",
				duedate: "22/02/2022",
				status: "Actief",
				to: `/coupon/1`,
			},
		],
	}),
};
</script>

