<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<v-container fluid class="pa-5 pa-md-15">
				<v-row>
					<v-col cols="8">
						<h1 class="font-weight-bold">Abonnementen | <span class="font-weight-regular">Overzicht</span></h1>
						<p>Een overzicht van al je abonnementen. Beheer en pas ze aan indien nodig.</p>
					</v-col>
					<v-col cols="4" class="ml-auto text-right">
						<v-btn color="secondary" elevation="0" @click="openMenu">Nieuwe abonnementen toevoegen</v-btn>
					</v-col>
				</v-row>
				<v-card elevation="0" class="p-3 h-100">
					<v-data-table :headers="headers" :items="subscriptions" :items-per-page="25" @click:row="handleClick"> </v-data-table>
				</v-card>
			</v-container>
		</v-main>
		<Drawer :direction="'right'" :exist="true" ref="LeftDrawer">
			<v-container fluid class="pa-5 pa-md-15">
				<h2>Nieuwe abonnementen toevoegen</h2>

				<v-row class="ma-4"></v-row>

				<v-row>
					<v-col cols="12">
						<v-text-field label="Klantnaam" outlined persistent-hint></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="ml-auto text-right">
						<v-btn color="secondary" right @click="newsubscription" elevation="0"> Aanmaken </v-btn>
					</v-col>
				</v-row>
			</v-container>
		</Drawer>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Drawer from "@/components/Drawer.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Drawer,
	},

	methods: {
		openMenu() {
			if (this.$refs.LeftDrawer.active) {
				this.$refs.LeftDrawer.close();
			} else {
				this.$refs.LeftDrawer.open();
			}
		},
		handleClick(v) {
			this.$router.push(v.to);
		},
		newsubscription() {
			// HIER WORD GEPOST NAAR DE BACKEND
			// DAARNA DOOR ROUTEN
		},
	},

	data: () => ({
		coupon: {
			id: 0,
			name: "",
			description: "",
		},
		breadcrumb: [
			{
				text: "Abonnementen",
				disabled: true,
				href: "/subscriptions",
			},
		],

		headers: [
			{
				text: "Abonnement",
				align: "start",
				sortable: false,
				value: "subscription",
			},
			{ text: "Totaal", value: "total" },
			{ text: "Startdatum", value: "start_date" },
			{ text: "Einddatum proefperiode", value: "trail_end_date" },
			{ text: "Volgende betaling", value: "next_payment" },
			{ text: "Vervaldatum", value: "due_date" },
			{ text: "Status", value: "status" },
		],
		subscriptions: [
			{
				subscription: "#12 - Gianni Hesseling",
				total: "€25,00 / week",
				start_date: "10%",
				description: "Dit is en omschrijving.",
				limit: "4/100",
				due_date: "22/02/2022",
				status: "Actief",
				to: `/subscription/1`,
			},
		],
	}),
};
</script>

