<template>
	<v-app>
		<Navigations :breadcrumbs="this.breadcrumb" />
		<v-main>
			<v-container fluid class="pa-5 pa-md-15">
				<v-row>
					<v-col cols="8">
						<h1 class="font-weight-bold">Producten | <span class="font-weight-regular">Overzicht</span></h1>
						<p>Beheer alle producten.</p>
					</v-col>
					<v-col cols="4" class="ml-auto text-right">
						<v-btn color="secondary" elevation="0" @click="openMenu">Nieuwe product toevoegen</v-btn>
					</v-col>
				</v-row>
				<v-card elevation="0" class="p-3 h-100">
					<v-data-table :loading="loading" :headers="headers" :items="products" :items-per-page="15" @click:row="handleClick">
						<template v-slot:item.stock="{ item }">
							<span v-if="item.stock === -1"> Unlimited </span>
							<span v-else>
								{{ item.stock }}
							</span>
						</template>
						<template v-slot:item.last_modified="{ item }">
							{{ new Date(item.last_modified * 1000).toLocaleString("en-US", dateformat) }}
						</template>
					</v-data-table>
				</v-card>
			</v-container>
		</v-main>
		<Drawer :direction="'right'" :exist="true" ref="LeftDrawer">
			<v-container fluid class="pa-5 pa-md-15">
				<h2>Nieuw product toevoegen</h2>

				<v-row class="ma-4"></v-row>
				<v-row>
					<v-col cols="12">
						<v-text-field :rules="config.rules.name" v-model="np.name" label="Productnaam" outlined persistent-hint></v-text-field>
						<v-textarea :rules="config.rules.description" v-model="np.meta.description" label="Omschrijving" outlined persistent-hint></v-textarea>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="ml-auto text-right">
						<v-btn color="secondary" right @click="submit" elevation="0">Aanmaken</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</Drawer>
	</v-app>
</template>
<script>
import Navigations from "../../components/Navigations.vue";
import Drawer from "@/components/Drawer.vue";
import config from "@/config";
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	components: {
		Navigations,
		Drawer,
	},
	data() {
		return {
			breadcrumb: [
				{
					text: "Producten",
					disabled: true,
					href: "/products",
				},
			],
			headers: [
				{
					text: "ID",
					align: "start",
					sortable: false,
					value: "id",
				},
				{ text: "Naam", value: "name" },
				{ text: "Laatst bijgewerkt", value: "last_modified" },
				{ text: "Voorraad", value: "stock" },
			],
			products: [
			],
			loading: true,
			dateformat: {},
			np: {
				name: "",
				meta: {
					description: ""
				}
			}
		}
	},
	computed: {
		...mapGetters({ token: 'gettoken', shop: 'getshop' }),
		config() {
			return config;
		}
	},
	methods: {
		openMenu() {
			if (this.$refs.LeftDrawer.active) {
				this.$refs.LeftDrawer.close();
			} else {
				this.$refs.LeftDrawer.open();
			}
		},
		handleClick(v) {
			this.$router.push(`/product/${v.id}`);
		},
		submit() {
			axios.put(`${config.url.api}/v1/orders/shop/${this.shop.id}/add/product`, this.np, {
				headers: {
					Authorization: `user ${this.token}`
				},
			}).then((res) => {
				this.products.push(res.data)
				this.$toast.success("Product aangemaakt", {
					position: "top-right",
					timeout: 5000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: "button",
					icon: true,
					rtl: false
				});
				this.$refs.LeftDrawer.close();
				this.$router.push({path:`product/${res.data.id}`})
			}).catch(() => {
				this.$toast.error("Fout bij het aanmaken van het product", {
					position: "top-right",
					timeout: 5000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: "button",
					icon: true,
					rtl: false
				});
			})
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		wait() {
			return new Promise(() => {
				if (this.token === null) {
					this.sleep(200).then(() => {
						if (this.token === null) {
							this.wait();
						} else {
							this.load()
						}
					})
				} else {
					this.load()
				}
			});
		},
		load() {
			axios.get(`${config.url.api}/v1/orders/shop/${this.shop.id}/products`, {
				headers: {
					Authorization: `user ${this.token}`
				}
			}).then((res) => {
				this.products = res.data
				this.loading = false
			})
		}
	},
	mounted() {
		this.dateformat = config.dateformat
		this.wait();
	},
};
</script>

