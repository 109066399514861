<template>
	<router-view :setting="setting" :submit="submit"></router-view>
</template>

<script>
export default {
	data() {
		return {
			setting: {
				/******************************************
				 * settings/general
				 ******************************************/

				// -- General details
				shopname: "Snelwebcenter",
				companyname: "Snelwebcenter",
				phonenumber: "0850160922",
				emailadres: "info@snelwebcenter.nl",
				emailadres_for_notifications: "meldingen@snelwebcenter.nl",
				website: "www.snelwebcenter.nl",
				// -- Adres
				adres: "moermanskweg 2-22",
				adres2: "nvt",
				postcode: "9723HM",
				location_state: "Groningen",
				country: "Nederland",
				// -- company details
				btw_id: "nlbtwnummer",
				kvk_nr: "68813449",
				iban: "NL89INGB12345678",
				// -- Social media
				facebook_url: "www.facebook.com",
				twitter_url: "www.twitter.com",
				linkedin_url: "www.linkedin.com",
				instagram_url: "www.instagram.com",
				pinterest_url: "www.pinterest.com",
				// -- shop logos
				logo: null,
				favicon: null,

				/******************************************
				 * settings/payments
				 ******************************************/

				invoiceprefix: "pre",
				invoicenextnumber: "123",
				invoicenumber: "2021-1234",

				paymentreminder1: 1,
				paymentreminder2: 2,
				paymentreminder3: 3,

				/******************************************
				 * settings/domainname
				 ******************************************/
				domainname: "snelwebcenter.nl",

				/******************************************
				 * settings/terms
				 ******************************************/
				termsdocument: null,

				/******************************************
				 * settings/widgets
				 ******************************************/
				thankyoupagewidget: "thankyou",
				checkoutpagewidget: "checkout",

				/******************************************
				 * settings/emailsettings
				 ******************************************/

				emailsforcollection: true,
				emailsforneworders: true,
				emailsforpaymentreminders: true,
				customemailadres: "mail@snelwebcenter.nl",

				/******************************************
				 * settings/emailtemplates
				 ******************************************/

				// -- Order bevestiging
				// - Mailtemplate 1
				mt_orderconfirmation_subject: "Bevestiging van checkout #[order_id]",
				mt_orderconfirmation_discription:
					"Beste [first_name], De factuur van je checkout is als bijlage toegevoegd in deze e-mail.",
				mt_orderconfirmation_signature: "Met vriendelijke groet, [store_name]",

				// -- Betaallink
				// - Mailtemplate 2
				mt_paymentlink_subject: "Betaallink van checkout #[order_id]",
				mt_paymentlink_discription:
					"Beste [first_name], De factuur van je bestellingen is als bijlage toegevoegd in deze e-mail.",
				mt_paymentlink_signature: "Met vriendelijke groet, [store_name]",

				// -- Checkout notificatie
				// - Mailtemplate 3
				mt_checkoutnotice_subject: "Nieuwe checkout #[order_id]",
				mt_checkoutnotice_discription:
					"Er is zojuist een nieuwe checkout geweest op je Kyano Order account.",
				mt_checkoutnotice_signature: "Met vriendelijke groet, [store_name]",

				// -- Factuur toesturen
				// - Mailtemplate 4
				mt_sendinvoice_subject: "Factuur #[invoice_nr] van [store_name]",
				mt_sendinvoice_discription:
					"Beste [first_name], Hierbij ontvang je de factuur van order #[order_id]",
				mt_sendinvoice_signature: "Met vriendelijke groet, [store_name]",

				// -- 1e betalings herinnering
				// - Mailtemplate 5
				mt_paymentreminder1_subject:
					"Betalingsherinnering voor factuur #[invoice_no]",
				mt_paymentreminder1_discription: "Beste [first_name], ",
				mt_paymentreminder1_signature: "Met vriendelijke groet, [store_name]",

				// -- 2e betalings herinnering
				// - Mailtemplate 6
				mt_paymentreminder2_subject:
					"2e betalingsherinnering voor factuur #[invoice_no]",
				mt_paymentreminder2_discription: "Beste [first_name], ",
				mt_paymentreminder2_signature: "Met vriendelijke groet, [store_name]",

				// -- 3e betalings herinnering
				// - Mailtemplate 7
				mt_paymentreminder3_subject:
					"3e betalingsherinnering voor factuur #[invoice_no]",
				mt_paymentreminder3_discription: "Beste [first_name], ",
				mt_paymentreminder3_signature: "Met vriendelijke groet, [store_name]",

				/******************************************
				 * settings/paymentmethodes
				 ******************************************/
				mollieliveapi: "HIER KOMT DE LIVE API KEY",
				mollietestapi: "HIER KOMT DE TEST API KEY",
				mollieteston: true,

				/******************************************
				 * settings/analytics
				 ******************************************/
				setting_analytics: [
					{
						id: "0",
						name: "Uitgeschakeld",
					},
				],
				googleanalytics: "UA-",
				googletagmanager: "TAG-",
				facebookpixel: "face",
			},
		};
	},
	methods: {
		submit() {

		},
	}
};
</script>

<style>
</style>