<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<v-container fluid class="pa-5 pa-md-15">
				<v-row>
					<v-col cols="8">
						<h1 class="font-weight-bold">
							Bedanktpagina's |
							<span class="font-weight-regular">Overzicht</span>
						</h1>
						<p>Een overzicht van al je Bedanktpagina's. Beheer en pas ze aan indien nodig.</p>
					</v-col>
					<v-col cols="4" class="ml-auto text-right">
						<v-btn color="secondary" elevation="0" @click="openMenu">Nieuwe Bedanktpagina toevoegen</v-btn>
					</v-col>
				</v-row>
				<v-card elevation="0" class="p-3 h-100">
					<v-data-table :headers="headers" :items="thankyoupage" :items-per-page="25" @click:row="handleClick"> </v-data-table>
				</v-card>
			</v-container>
		</v-main>
		<Drawer :direction="'right'" :exist="true" ref="LeftDrawer">
			<v-container fluid class="pa-5 pa-md-15">
				<h2>Nieuwe Bedanktpagina toevoegen</h2>

				<v-row class="ma-4"></v-row>

				<v-row>
					<v-col cols="12">
						<v-text-field label="Bedanktpagina naam" outlined persistent-hint></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="ml-auto text-right">
						<v-btn color="secondary" right @click="newthankyoupage" elevation="0"> Aanmaken </v-btn>
					</v-col>
				</v-row>
			</v-container>
		</Drawer>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Drawer from "@/components/Drawer.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Drawer,
	},
	methods: {
		openMenu() {
			if (this.$refs.LeftDrawer.active) {
				this.$refs.LeftDrawer.close();
			} else {
				this.$refs.LeftDrawer.open();
			}
		},
		handleClick(v) {
			this.$router.push(v.to);
		},
		newthankyoupage() {
			// HIER WORD GEPOST NAAR DE BACKEND
			// DAARNA DOOR ROUTEN
		},
	},

	data: () => ({
		coupon: {
			id: 0,
			name: "",
			description: "",
		},
		breadcrumb: [
			{
				text: "Bedanktpagina's",
				disabled: true,
				href: "/thankyoupages",
			},
		],
		headers: [
			{
				text: "Naam",
				align: "start",
				sortable: false,
				value: "name",
			},
			{ text: "Checkout", value: "checkoutpages" },
		],
		thankyoupage: [
			{
				name: "Bedankt voor je bestelling",
				checkoutpages: "kyano premium",
				to: `/thankyoupage/1`,
			}
		],

	}),
};
</script>

